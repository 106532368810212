<template>
  <div class="pa-10 d-flex align-center justify-center">
    <div class="primary--text f12 mx-2">
      Terms of Use
    </div>
      <div class="primary--text f12 mx-2">
      Data Privacy
    </div>
  </div>
</template>

<script>
export default {

}
</script>
